import React from "react";
import {SearchResult} from './searchresult';
import {SpinnerIcon} from './svg/spinner';
import {Helmet} from "react-helmet";
import {MagnifierIcon} from './svg/magnifier';
import './../styles/search';


export class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: '',
            waitingForAPI: false,
            requestFailed: false,
            results: [],
        }
        this.props.setStandardView(false);
        this.config = {
            env: 'dev',
            // env: 'prod',
            apiUrl: 'https://hubble-server.firebaseapp.com/api/v1',
        }

        this.processSubmit = this.processSubmit.bind(this);
        this.performSearchAvailable = this.performSearchAvailable.bind(this);
        this.performSearchAvail = this.performSearchAvailable.bind(this);

        this.dataLayer = window.dataLayer = window.dataLayer || [];
    };

    componentWillUnmount() {
        this.props.setStandardView(true);
    }

    log(message, group = null, type = null) {
        if (this.config.env == 'dev') {
            if (group) {
                message = "[" + group + "] " + message;
            }

            switch(type) {
                case 'error':
                    console.error(message);
                    break;
                case 'warn':
                    console.warn(message);
                    break;
                case 'table':
                    console.table(message);
                    break;
                case 'info':
                    console.info('%c' + message, 'background: white; color: black; display: block;');
                    break;
                case 'success':
                    console.info('%c' + message, 'background: green; color: white; display: block;');
                    break;
                case 'fail':
                    console.error('%c' + message, 'background: red; color: white; display: block;');
                    break;
                default:
                    console.log(message);
            }
        }
    }

    processSubmit(e) {
        e.preventDefault()
        let val = e.target.searchField.value

        if (val != this.state.query) {
            this.setState({requestFailed: false});
            if (val = this.isQueryValid(val)) {
                this.setState({
                    query: val,
                }, () => {
                    this.performSearchAvailable()
                });
            }
            else {
                this.dataLayer.push({
                    'event':'hubble',
                    'type': 'search',
                    'status': 'failed',
                    'value': val,
                    'message': 'The query is invalid, not executing request.',
                });
            }
        }
        else {
            this.dataLayer.push({
                'event':'hubble',
                'type': 'search',
                'status': 'failed',
                'value': val,
                'message': 'The query did not change since the last request',
            });
        }
    }

    performSearchAvailable() {
        this.log('Performing search for "' + this.state.query + '"...', 'search') 
        this.setState({results: []})
        
        if (this.props.standardView === false) {
            this.props.setStandardView(true)
        }
        this.setState({waitingForAPI: true})
        this.dataLayer.push({
            'event':'hubble',
            'type': 'search',
            'status': 'processing',
            'value': this.state.query,
            'message': 'Making the request to the API.',
        });

        var apiRequestUrl = this.config.apiUrl + '/domains/isavailable/' + this.state.query
        this.log('Fetching URL "' + apiRequestUrl + '"...', 'api', 'info')
        let $this = this;
        fetch(apiRequestUrl)
        .then(
            res => res.json()
        )
        .then(response => {
            $this.log(response);
            $this.setState({waitingForAPI: false});
            if (response.data && response.domain) {
                var newResult = {
                    key: response.domain.full,
                    name: response.domain.full,
                    isAvailable: response.data.isAvailable,
                    links: [{
                        key: `${response.domain.full}_register`,
                        url: `https://www.versio.nl/domeinnamen?pa=112481gbeyf&domain=${response.domain.full}`,
                        type: 'button',
                        caption: 'Register',
                    }],
                    aftermarket: {
                        searching: false,
                        results: []
                    }
                }
                if (!response.data.isAvailable) {
                    newResult.links = [{
                        key: `${response.domain.full}_visit`,
                        url: `http://${response.domain.full}`,
                        type: 'subtle',
                        caption: 'Visit website',
                    }];

                    newResult.aftermarket = {
                        searching: true,
                        results: []
                    }

                    fetch(`${this.config.apiUrl}/domains/isforsale/${response.domain.full}`)
                    .then(fsresponse => fsresponse.json())
                    .then(function (fsresponse) {
                        let results = [];
                        console.warn(fsresponse.data.items);
                        fsresponse.data.items.map(item => {
                            if (item.bidding.active === true) {
                                let result = {
                                    key: `${response.domain.full}_${item.source}_bidding`,
                                    source: item.source,
                                    link: item.link,
                                    type: 'Make an offer',
                                    cta: 'Make an offer',
                                    price: `> ${item.bidding.currency}${item.bidding.minimum}`,
                                    rawprice: item.bidding.minimum,
                                    extra: `The minimum offer is currently set to ${item.bidding.currency}${item.bidding.minimum}.`
                                }
                                results.push(result);
                            }
                            if (item.price.active === true) {
                                if (item.price.amount) {
                                    let result = {
                                        key: `${response.domain.full}_${item.source}_buynow`,
                                        source: item.source,
                                        link: item.link,
                                        type: 'Fixed price',
                                        cta: 'Buy now',
                                        price: `${item.price.currency}${item.price.amount}`,
                                        rawprice: item.price.amount,
                                        extra: (
                                            (item.price.extra && item.price.extra.length) ? 
                                            `This domain name can be bought for ${item.price.currency}${item.price.amount} without any negotiations. ${item.price.extra}.` : 
                                            `This domain name can be bought for ${item.price.currency}${item.price.amount} without any negotiations.`)
                                    }
                                    results.push(result);
                                }
                            }
                        })
                        newResult.aftermarket = {
                            searching: false,
                            results: results
                        }
                        $this.setState({results: [newResult]});
                    })
                    .catch((error) => {
                        this.log(error);
                    });
                }
                $this.setState({results: [newResult]});

                this.dataLayer.push({
                    'event':'hubble',
                    'type': 'search',
                    'status': 'success',
                    'value': $this.state.query,
                    'message': 'status: ' + newResult.status,
                });
            }
            else {
                this.dataLayer.push({
                    'event':'hubble',
                    'type': 'search',
                    'status': 'failed',
                    'value': $this.state.query,
                    'message': 'The response contained an invalid object.',
                });
            }
        })
        .catch(function(error) {
            $this.setState({
                waitingForAPI: false,
                requestFailed: true,
            });
            $this.log('Request failed', 'fail');

            this.dataLayer.push({
                'event':'hubble',
                'type': 'search',
                'status': 'failed',
                'value': $this.state.query,
                'message': 'The request to the API failed.',
            });
        });
    }

    isQueryValid(query) {
        if ((query.match(/\./g) || []).length > 1) {
            this.log('The given domain "' + query + '" contains too many dots!', 'validation', 'fail')
            return false;
        }

        var regexNameWithExtension = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/);
        var regexNameWithoutExtension = new RegExp(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]+$/);

        if(!regexNameWithExtension.test(query)) {
            if(!regexNameWithoutExtension.test(query)) {
                this.log('The given domain "' + query + '" is not valid', 'validation', 'fail')
                return false;
            }
            this.log('No extension found, proceeding with "' + query + '".com.', 'validation', 'info');
            query = `${query}.com`
        }
        else {
            // TODO: perform extra validating rules for valid/supporterd extensions...
            this.log('Proceeding with "' + query + '" as valid name and extension.', 'validation', 'info');
        }
        return query;
    }

    render() {
        let sectionClasses = Array('search');
        return (
            <section className={sectionClasses.join(' ')}>
                <SearchForm processSubmit={this.processSubmit}></SearchForm>
                <SearchResults 
                    results={this.state.results}
                    waitingForAPI={this.state.waitingForAPI}
                    requestFailed={this.state.requestFailed}>
                </SearchResults>
            </section>
        );
    }    
}

class SearchForm extends React.Component {
    componentDidMount() {
    //   this.nameInput.focus()
    }
    render() {
        return (
            <section className="search-form-container">
                <Helmet>
                    <title>Hubble</title>
                </Helmet>
                <form onSubmit={this.props.processSubmit}>
                    <div className = "input-group mb-3">
                        <input 
                            type = "search" 
                            id = "searchField"
                            className = "searchfield"
                            aria-label = "Enter the domain name and check it's availibilty"
                            placeholder = "Type a domain name, e.g.: test.com"
                            autoComplete = "off"
                            ref = {(input) => { this.nameInput = input; }}
                            // autoFocus 
                            />
                        <button className="btn" type="submit">Search <span className="icon"><MagnifierIcon id="magnifier" title="Search" /></span></button>
                    </div>
                </form>
            </section>
        );
    }
}

class SearchResults extends React.Component {
    render() {
        return (
                this.props.requestFailed ? (
                    <section className = "search-results-container">
                        <h3>Sorry, it seems like something went wrong...</h3>
                    </section>
                ) : (
                    <section className = "search-results-container">
                        <SpinnerIcon id="SpinnerSearchResults" title="Checking availibility..." className={this.props.waitingForAPI ? "" : "hidden"} />
                        <ul id="searchResults">
                            {this.props.results.map(result => (
                                <li><SearchResult key={result.name} data={result} /></li>
                            ))}
                        </ul>
                    </section>
                )
        );
    }
}