import React from "react";
import './../styles/about';

export class About extends React.Component {

    constructor(props) {
        super(props);
        this.props.setStandardView(true);
    };

    render() {
        return (
            <section className="about">
                <h1>About</h1>
                <p>Hubble's purpose is to simplify your search for an available domain name for your next project. This tools checks the availibility of the requested domain name and, if taken, searches for it on well known after markets such as sedo.com, where it could be listed as for sale/for acution/...</p>
                <p>We are not a registrar nor do we sell domain names ourselves. We're just here to make your search easier.</p>
                <p>hubble.domains is a project created by <a href="https://www.linkedin.com/in/kristofgrenson/" target="_blank">Kristof Grenson</a> in 2018.</p>
            </section>
        )
    }
}
