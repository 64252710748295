import React from "react";

export class SuccessIcon extends React.Component {
    render() {
        const propTitle = (this.props.title ? this.props.title : "Success");
        const propId = (this.props.id ? this.props.id : "");
        const propClass = (this.props.className ? this.props.className + " successicon" : "successicon");
        const propWidth = (this.props.width ? this.props.width : "100%");
        const propHeight = (this.props.height ? this.props.height : "100%");
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id={propId} width={propWidth} height={propHeight} viewBox="0 0 8 8" className={propClass}>
                <title>{propTitle}</title>
                <path d="M4 0c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm2 1.78l.72.72-3.22 3.22-1.72-1.72.72-.72 1 1 2.5-2.5z" />
            </svg>
        )
    }
}
