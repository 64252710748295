import React from "react";
import styled, {keyframes} from 'styled-components';
import {fadeInUpBig} from 'react-animations'
import {SpinnerIcon} from './svg/spinner';
import {LinkIcon} from './svg/link';
import {SuccessIcon} from './svg/success';
import {FailedIcon} from './svg/failed';
import './../styles/searchresult';

export class SearchResult extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data,
       }
       this.firstRun = true;
        // this.toggleNav = this.toggleNav.bind(this);
   };

    render() {
        const animationIn = keyframes`${fadeInUpBig}`;
        const animatedArticle = styled.article`
        animation: 200ms ${animationIn};
        opacity: 0.5;
        `;
        const nonAnimatedArticle = styled.article`
        animation: 0ms ${animationIn};
        opacity: 1;
        `;

        const AnimatedResult = (this.firstRun ? animatedArticle : nonAnimatedArticle);
        if (this.firstRun) {
            this.firstRun = false;
        }
        
console.warn(this.state.data.aftermarket.results);

        return (
            <AnimatedResult key = {this.state.data.name} className = "search-result">
                {this.state.data.isAvailable ? (
                    <h3>{this.state.data.name}</h3>
                ) : (
                    <h3><a href={"http://" + this.state.data.name} target="_blank">{this.state.data.name} <LinkIcon title={"Visit "+ this.state.data.name} /></a></h3>
                )}
                <div className="section section-availibility">
                    <h4>Availibity?</h4>
                    {this.state.data.isAvailable ? (
                        <p>
                            {/* <span className="label">status: </span> */}
                            <span className="status status-success"><SuccessIcon title={this.state.data.name + " is available for registration."} />Available!</span>
                            <span className="extra">{this.state.data.name} is still available for registration.</span>
                        </p>
                    ) : (
                        <p>
                            {/* <span className="label">status: </span> */}
                            <span className="status status-failed"><FailedIcon title={this.state.data.name + " is no longer available for registration."} />Not available</span>
                            <span className="extra">Somebody already registered {this.state.data.name}.</span>
                        </p>
                    )}
                </div>
                {/* <span className = "primary-links">
                    {this.state.data.links.map(link => (
                        <a 
                            key = {link.url}
                            href = {link.url} 
                            title = {link.caption} 
                            className = {link.type}
                            target = "_blank">{link.caption}</a>
                    ))}
                </span> */}

                {!this.state.data.isAvailable ? (
                        this.state.data.aftermarket.searching ? (
                            <div className="section section-aftermarket">
                                <h4>For sale/auction/rent?</h4>
                                <p>
                                    {/* <span className="label">status: </span> */}
                                    <span className="status status-waiting">
                                        <SpinnerIcon id="SpinnerSearchResults" className={this.state.data.aftermarket.searching ? "" : "hidden"} title="searching..." className="smaller" />
                                    </span>
                                    <span className="extra">We're currently searching for {this.state.data.name} on the aftermarket websites.</span>
                                </p>
                            </div>
                        ) : (
                            this.state.data.aftermarket.results.length > 0 ? (
                                <span>
                                    <div className="section section-aftermarket">
                                        <h4>For sale/auction/rent?</h4>
                                        <p>
                                            {/* <span className="label">status: </span> */}
                                            <span className="status status-success"><SuccessIcon title={this.state.data.name + " was found on the aftermarket."} />Found!</span>
                                            <span className="extra">We found a match for {this.state.data.name} on {this.state.data.aftermarket.results.length} aftermarket website{this.state.data.aftermarket.results.length > 1 ? 's' : ''}.</span>
                                        </p>
                                    </div>
                                    <div className="section section-links">
                                        <h4>Listings</h4>
                                        <ul>
                                            {this.state.data.aftermarket.results.map(result => (
                                                <li>
                                                    <a href={result.link} target="_blank">
                                                        <span className="icon">{result.source}</span>
                                                        <span className="name">{result.source}</span>
                                                        <span className="type">{result.type}</span>
                                                        <span className="price">{result.price.replace('eur', '€ ').replace('usd', '$ ')}</span>
                                                        {/* <span className="cta">{result.cta}</span>
                                                        <span className="extra">{result.extra}</span> */}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </span>
                            ) : (
                                <div className="section section-aftermarket">
                                    <h4>For sale/auction/rent?</h4>
                                    <p>
                                        {/* <span className="label">status: </span> */}
                                        <span className="status status-failed"><FailedIcon title={this.state.data.name + " could not be found on the aftermarket."} />Not found!</span>
                                        <span className="extra">We could not find any listings for {this.state.data.name} on the aftermarket.</span>
                                        {
                                            //TODO: list link to website + cta to WHOIS?
                                        }
                                    </p>
                                </div>
                            )
                        )
                ) : (
                    <div className="section section-links">
                        <h4>Register now!</h4>
                        <ul>
                            <li>
                                <a href={"https://be.godaddy.com/domainsearch/find?checkAvail=1&domainToCheck=" + this.state.data.name} target="_blank">
                                    <span className="icon">GoDaddy</span>
                                    <span className="name">GoDaddy</span>
                                    <span className="price"></span>
                                    <span className="cta">Register</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                )}
            </AnimatedResult>
        );
   }
}
