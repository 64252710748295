import React from "react";

export class LinkIcon extends React.Component {
    render() {
        const propTitle = (this.props.title ? this.props.title : "Link to domain name");
        const propId = (this.props.id ? this.props.id : "");
        const propClass = (this.props.className ? this.props.className + " linkicon" : "linkicon");
        const propWidth = (this.props.width ? this.props.width : "100%");
        const propHeight = (this.props.height ? this.props.height : "100%");
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id={propId} width={propWidth} height={propHeight} viewBox="0 0 8 8" className={propClass}>
                <title>{propTitle}</title>
                <path d="M0 0v8h8v-2h-1v1h-6v-6h1v-1h-2zm4 0l1.5 1.5-2.5 2.5 1 1 2.5-2.5 1.5 1.5v-4h-4z" />
            </svg>
        )
    }
}
