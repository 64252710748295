import React from "react";

export class HamburgerIcon extends React.Component {
    render() {
        const propWidth = (this.props.width ? this.props.width : "100%");
        const propHeight = (this.props.height ? this.props.height : "100%");
        const propId = (this.props.id ? this.props.id : "");
        const propClass = (this.props.className ? this.props.className : "");
        const propTitle = (this.props.title ? this.props.title : "Open");
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id={propId} width={propWidth} height={propHeight} viewBox="0 0 8 8" className={propClass}>
                <title>{propTitle}</title>
                <path d="M0 0v1h8v-1h-8zm0 2.97v1h8v-1h-8zm0 3v1h8v-1h-8z" transform="translate(0 1)" />
            </svg>
        )
    }
}
