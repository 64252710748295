import React from "react";

export class CloseIcon extends React.Component {
    render() {
        const propWidth = (this.props.width ? this.props.width : "100%");
        const propHeight = (this.props.height ? this.props.height : "100%");
        const propId = (this.props.id ? this.props.id : "");
        const propClass = (this.props.className ? this.props.className : "");
        const propTitle = (this.props.title ? this.props.title : "Close");
        return (
            <svg xmlns="http://www.w3.org/2000/svg" id={propId} width={propWidth} height={propHeight} viewBox="0 0 8 8" className={propClass}>
                <title>{propTitle}</title>
                <path d="M1.41 0l-1.41 1.41.72.72 1.78 1.81-1.78 1.78-.72.69 1.41 1.44.72-.72 1.81-1.81 1.78 1.81.69.72 1.44-1.44-.72-.69-1.81-1.78 1.81-1.81.72-.72-1.44-1.41-.69.72-1.78 1.78-1.81-1.78-.72-.72z" />
            </svg>
        )
    }
}
