import React from "react";
import {NavLink, Link, withRouter} from "react-router-dom";
import {Logo} from './svg/logo';
import {MagnifierIcon} from './svg/magnifier';
import {HamburgerIcon} from './svg/hamburger';
import {CloseIcon} from './svg/close';
import './../styles/navigation';

export const Navigation = withRouter(props => <Nav {...props}/>);

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMobileNav: false,
        }
        this.toggleNav = this.toggleNav.bind(this);
        this.hideNav = this.hideNav.bind(this);
        this.isHome = this.isHome.bind(this);

        this.dataLayer = window.dataLayer = window.dataLayer || [];
    };

    toggleNav() {
        this.setState({showMobileNav: !this.state.showMobileNav});
        this.dataLayer.push({
            'event':'hubble',
            'type': 'click',
            'status': 'success', 
            'value': 'toggle', 
        });
    }

    hideNav() {
        this.setState({showMobileNav: false});
    }

    isHome() {
        return (this.props.location.pathname == "/" ? true : false);
    }

    render() {
        let headerClass = "";
        let menuClass = "open";
        let logoViewBox = "113 0 194 89";
        if (!this.props.standardView) {
            logoViewBox = "114 20 194 47";
            headerClass = "hero";
        }
        return (
            <header className={headerClass}>
                <span id="burger" onClick={this.toggleNav}>
                    <HamburgerIcon id="burger_open" title="Open menu" className={this.state.showMobileNav ? 'mobile_hidden' : ''} />
                    <CloseIcon id="burger_close" title="Close menu" className={this.state.showMobileNav ? '' : 'mobile_hidden'} />
                </span>
                <nav className={this.state.showMobileNav ? '' : 'mobile_hidden'}>
                    <ul className={menuClass}>
                        {(this.isHome() ? "" : <li><NavLink exact={true} to="/" onClick={this.hideNav}><MagnifierIcon id="magnifier" title="Search" />Search</NavLink></li>)}
                        {/* <li><NavLink to="/help" onClick={this.hideNav}>Help</NavLink></li> */}
                        <li><NavLink to="/about" onClick={this.hideNav}>About</NavLink></li>
                    </ul>
                </nav>
                <Link exact="true" to="/" className="icon">
                    <Logo viewBox="0 0 90 89" />
                </Link>
                <Link exact="true" to="/" className="logo">
                    <Logo viewBox={logoViewBox} />
                </Link>
            </header>
        )
    }
}
