import React from "react";
import {Helmet} from "react-helmet";
import {render} from "react-dom";
import { 
    BrowserRouter,
    Route
} from "react-router-dom";

import {Navigation} from './components/navigation';
import {Search} from './components/search';
import {About} from './components/about';
import './styles/app';

import favicon from './resources/icons/icon-purple.png';
import ogimage from './resources/images/og-image.png';
import appletouchicon from './resources/images/apple-touch-icon.png';
import maskicon from './resources/images/safari-pinned-tab.svg';

import launch640x1136 from './resources/images/launch-640x1136.png';
import launch750x1294 from './resources/images/launch-750x1294.png';
import launch1242x2148 from './resources/images/launch-1242x2148.png';
import launch1125x2436 from './resources/images/launch-1125x2436.png';
import launch1536x2048 from './resources/images/launch-1536x2048.png';
import launch1668x2224 from './resources/images/launch-1668x2224.png';
import launch2048x2732 from './resources/images/launch-2048x2732.png';

class App extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            standardView: null,
            searching: false,
        };

        this.setStandardView = this.setStandardView.bind(this);
    }
    
    setStandardView(setting) {
        this.setState({standardView: setting});
    };

    render() {
        return (
            <BrowserRouter>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Hubble</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1" />

                    <meta property="og:title" content="Hubble" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content="https://hubble.domains" />
                    <meta property="og:image" content={"https://hubble.domains" + ogimage} />

                    <link rel="icon" type="image/png" href={favicon} sizes="24x24" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                    <meta name="apple-mobile-web-app-title" content="Hubble" />
                    <link rel="apple-touch-icon" sizes="180x180" href={appletouchicon} />
                    <link rel="manifest" href="/site.webmanifest" />
                    <meta name="msapplication-TileColor" content="#382e89" />
                    <meta name="theme-color" content="#382e89" />
                    <link rel="mask-icon" href={maskicon} color="#382e89" />

                    <link rel="apple-touch-startup-image" href={launch640x1136} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch750x1294} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch1242x2148} media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch1125x2436} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch1536x2048} media="(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch1668x2224} media="(min-device-width: 834px) and (max-device-width: 834px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)" />
                    <link rel="apple-touch-startup-image" href={launch2048x2732} media="(min-device-width: 1024px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)" />
                </Helmet>
                <Navigation standardView={this.state.standardView} />
                <Route 
                    exact 
                    path="/" 
                    render={(props) => <Search {...props} standardView={this.state.standardView} setStandardView={this.setStandardView} />} 
                />
                <Route 
                    exact 
                    path="/about" 
                    render={(props) => <About {...props} standardView={this.state.standardView} setStandardView={this.setStandardView} />} 
                />
            </BrowserRouter>
        );
    }    
}

render(<App />, document.getElementById("hubble"));